import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Home() {
  const [name, setName] = useState("");
  const [role, setRole] = useState("Audience");
  const [roomId, setRoomId] = "9";

  const navigate = useNavigate();

  const navigateToRoom = () => {
    navigate(`/room/${roomId}`, {
      state: { name: name, role: role },
    });
  };
  return (
    <div>
      <h1>Join a room</h1>
      <input
        type="text"
        placeholder="Enter your Name"
        onChange={(e) => setName(e.target.value)}
      />{" "}
      <br />
      {/* <input
        type="text"
        placeholder="Enter room id"
        onChange={(e) => setRoomId(e.target.value)}
      /> */}
      {/* <div>
        <label>
          <input
            type="Radio"
            value={"Host"}
            checked={role === "Host"}
            onChange={(e) => setRole(e.target.value)}
          />
          {"Host"}
        </label>
        <label>
          <input
            type="Radio"
            value={"Audience"}
            checked={role === "Audience"}
            onChange={(e) => setRole(e.target.value)}
          />
          {"Audience"}
        </label>
      </div> */}
      <button onClick={navigateToRoom}>Join Livestream</button>
    </div>
  );
}
export default Home;
