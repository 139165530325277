import { useLocation, useParams } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";

function Room() {
  const { roomId } = useParams();
  const location = useLocation();
  const { name, role } = location.state || {name: "Guest", role:"Audience"};


  function randomID(len) {
    let result = "";
    if (result) return result;
    var chars =
        "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
      maxPos = chars.length,
      i;
    len = len || 5;
    for (i = 0; i < len; i++) {
      result += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return result;
  }

  const roleCondition =
    role === "Host"
      ? ZegoUIKitPrebuilt.Host
      : //   : role_str === "Cohost"
        //   ? ZegoUIKitPrebuilt.Cohost
        ZegoUIKitPrebuilt.Audience;

  const sharedLinks = [
    {
      name: "Join as Audience",
      url: `${window.location.origin}/room/${roomId}`,
    },
  ];

  const appID = 1509601827;
  const serverSecret = "a4237276a7b06de72614808f7cd9ff42";
  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
    appID,
    serverSecret,
    roomId,
    randomID(5),
    name
  );

  let myMeeting = async (element) => {
    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
         role:  roleCondition,
        },
      },
      sharedLinks,
    });
  };

  return (

    <div>
        <h1>Test</h1>
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ width: "100vw", height: "100vh" }}
      ></div>
    </div>
  );
}
export default Room; 