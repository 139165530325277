// import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Host() {
  const [roomId] = "9";
  const navigate = useNavigate();

  const startLiveStream = () => {
    // Simulate host starting the stream (backend/API would track this)
    sessionStorage.setItem(`room_${roomId}_live`, "true");
    navigate(`/room/${roomId}`, {
      state: { host: true, role: "Host" , name: "Hammerton"},
    });
  };

  return (
    <div>
      <h1>Start a Live Stream</h1>
      {/* <input@if (condition) 
        
      @else 
      
      @endif
        type="text"
        placeholder="Enter room ID"
        value={roomId}
        onChange={(e) => setRoomId(e.target.value)}
      /> */}
      <br />
      <button onClick={startLiveStream}>Start Stream</button>
    </div>
  );
}

export default Host;
